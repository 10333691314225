import { isExpired } from "react-jwt";
import { LocalStorageService } from "./local-storage.service";
import { SessionService } from "./session.service";
import { refreshToken } from "../../pages/auth/authSlice";
import axios from "axios";
const { REACT_APP_BASE_URL} = process.env;
// import { useDispatch } from "react-redux";

//will be removed after test
// const testToken = "";
// const dispatch = useDispatch();

const AuthService = {
   getAccessToken() {
      return LocalStorageService.getItem("atoken") || null; // will be null after test
   },

   getRefreshToken() {
      return LocalStorageService.getItem("rtoken") || null; // will be null after test;
   },

   setTokens(aToken, rToken) {
      LocalStorageService.setItem("atoken", aToken);
      LocalStorageService.setItem("rtoken", rToken);
   },

   refreshToken: async (refreshToken) => {
      try {
        const response = await axios.post(`${REACT_APP_BASE_URL}/Accounts/refresh-token`, {
          refreshToken: refreshToken
        });
  
        if (response.data.success) {
          // Assuming the response contains the new tokens
         //  const { accessToken, refreshToken } = response.data;
          return response.data;
        } else {
          throw new Error("Failed to refresh token");
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
        throw error;
      }
    },

   isTokenExpired() {
      const token = this.getAccessToken();

      if (token === null || token === "") {
         return true;
      } else {
         return isExpired(token);
      }
   },

   isAuthenticated() {
      const token = this.getAccessToken();

      // if(LocalStorageService.getItem('atoken') && LocalStorageService.getItem('rtoken') && isExpired(token)) {
      //    dispatch(refreshToken(LocalStorageService.getItem('rtoken')));
      // }

      return !isExpired(token);
   },

   isAuthorized(allowedRoles) {
      const user = AuthService.getUser();

      let userRoles;

      if (user !== null) {
         userRoles = user?.roles.map((role) => {
            return role.id;
         });
      } else {
         userRoles = ["-1"];
      }

      const contains = allowedRoles.some((element) => {
         return userRoles.indexOf(element) !== -1;
      });
      return contains;
   },

   setUser(model) {
      LocalStorageService.setItem("user", model);
   },

   getUser() {
      if (this.isAuthenticated()) {
         return LocalStorageService.getItem("user");
      } else {
         return null;
      }
   },

   logout() {
      LocalStorageService.removeItem("user");
      LocalStorageService.removeItem("atoken");
      LocalStorageService.removeItem("rtoken");
      LocalStorageService.removeItem("orgId");
      SessionService.clear();
   }
};

export default AuthService;
