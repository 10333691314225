import React, { lazy, Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Layout, LayoutSplashScreen } from "../_metronic/layout";
import { AdminLayout } from "../_metronic/layout/components/AdminLayout";
import ProtectedRoute from "./base/components/ProtectedRoute";
import AuthService from "./base/services/authentication.service";
import { AuthPage } from "./pages/auth/routes";
import { UserRolesEnum } from "./pages/auth/userRoles.enum";
import { Error } from "./pages/error/Error.js";
import LoginForAfrica from "./pages/auth/forAfricaLogin/LoginForAfrica.jsx";
import SuccessPaymentInfo from "./pages/account/SuccessPaymentInfo.js";
import FailedPaymentInfo from "./pages/account/FailedPaymentInfo.js";

const AdminPages = lazy(() => import("./pages/admin/routes"));
const WorkspacePages = lazy(() => import("./pages/workspace/routes"));

export function Routess() {
   const [isAuthenticated, setisAuth] = useState(() => AuthService.isAuthenticated());

   const location = useLocation();

   useEffect(() => {
      setisAuth(AuthService.isAuthenticated());
   }, [location, setisAuth]);

   return (
      <Suspense fallback={<LayoutSplashScreen />}>
         <Routes>
            {!isAuthenticated ? (
               /*Render auth page when user at `/auth` and not authorized.*/

               process.env.PUBLIC_URL == "https://forafrika.kaporg.com" ? (
                  <Route path="/*" element={<LoginForAfrica />} />
               ) : (
                  <Route path="/*" element={<AuthPage />} />
               )
            ) : (
               //  :

               /*Otherwise Navigate to root page (`/`)*/
               <>
                  {/* ADD Routes for admin and workspace when route empty */}
                  {/* <Route path="/" element={<Navigate to="/admin/workspace" />} />
                  <Route path="/auth/*" element={<Navigate to="/admin" />} /> */}

                  <Route element={<Layout />} /* Main Content with Layout */>
                     {/* module */}
                     <Route
                        element={
                           <ProtectedRoute
                              allowedRoles={[
                                 UserRolesEnum.SuperAdmin,
                                 UserRolesEnum.InternationalOrganization,
                                 UserRolesEnum.NationalOrganization,
                                 UserRolesEnum.Manager,
                                 UserRolesEnum.Donor,
                                 UserRolesEnum.Staff,
                                 UserRolesEnum.Consultant
                              ]}
                           />
                        }
                     >
                        <Route path="/*" element={<WorkspacePages />} />
                     </Route>
                     {/* module */}
                  </Route>
                  <Route element={<AdminLayout />}>
                     <Route path="/admin/*" element={<AdminPages />} />
                  </Route>
               </>
            )}
            <Route path="/success-payment-information" element={<SuccessPaymentInfo />} />
            <Route path="/failed-payment-information" element={<FailedPaymentInfo />} />
            <Route
               path="/unauthorized"
               element={
                  <Error
                     status="401"
                     title="401"
                     subTitle="Sorry, you are not authorized to access this page."
                     hasBackButton
                  />
               }
            />
            <Route
               path="/404"
               element={
                  <Error
                     status="404"
                     title="404"
                     subTitle="Sorry, the page you visited does not exist."
                     hasBackButton
                  />
               }
            />
            <Route
               path="/500"
               element={
                  <Error
                     title="Oops..."
                     subTitle="Looks like something went wrong. We're working on it"
                     hasBackButton
                  />
               }
            />
            <Route
               path="*"
               element={
                  <Error
                     title="Oops..."
                     subTitle="Looks like something went wrong. We're working on it"
                     hasBackButton
                  />
               }
            />
         </Routes>
      </Suspense>
   );
}
